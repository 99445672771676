:root {
  --main-background: #171717;
  --btn-background: #2F2F2F;
  --text-color-white: #fff;
  --text-color-black: #000000;
  --main-color: #9E50ED;
  --main-btn-hover: #D459FF;
  --orange: #B9B9B9;
  --accent: #39ECE5;
  --form-background: #F9F5EF;
  --main-color-filter: brightness(0) saturate(100%) invert(69%) sepia(73%) saturate(283%) hue-rotate(125deg) brightness(87%) contrast(88%);
}

@font-face {
  font-family: "Agrandir-GrandHeavy";
  font-display: block;
  src: url("/public/fonts/PPAgrandir-GrandHeavy.otf") format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-display: block;
  src: url("/public/fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  font-display: block;
  src: url("/public/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  font-display: block;
  src: url("/public/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-ExtraBold";
  font-display: block;
  src: url("/public/fonts/Raleway-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  font-display: block;
  src: url("/public/fonts/Lato-Bold.ttf") format("truetype");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: Montserrat;
  font-size: 14px;
  scroll-behavior: smooth;
  background-color: var(--main-background);
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  @include font-size(var(--bs-body-font-size));
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  /* background-color: var(--bs-body-bg);  */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
  background-color: transparent;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: var(--text-color-white);
  background-image: url('../public/images/main-bg.jpg');
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-color: transparent;
}
body.no-scroll { 
  overflow: hidden;
}
@media (max-width: 767px) {
  html {
    background-image: url('../public/images/main-bg-mobile.webp');
    background-size: cover;
    background-repeat: repeat-y;
  }
  body {
    background-image: none;
  }
}
#root {
  background-color: transparent;
}
a {
  color: var(--text-color-white);
  text-decoration: none;
}

a:hover {
  color: var(--main-color);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

img, svg {
  vertical-align: middle;
}
.main-page-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}
.main-container-pt {
  padding-top: 11.5rem;
}
.content-container {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.news-career-content-container {
  position: relative;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  min-height: calc(100vh - 155px);
  margin: 0 auto;
}
.main-page-title-left h2,
.main-page-title h2 {
  color: var(--text-color-white);
  font-family: "Raleway-ExtraBold";
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
  position: relative;
}
.main-page-title-left h2:before,
.main-page-title h2:before {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 18.929rem;
  height: 0.286rem;
  background: linear-gradient(to right, rgba(148, 68, 191, 1), rgba(128, 139, 230, 1), rgba(57, 236, 229, 1));
}
.main-page-title-left h2:before {
  left: 0;
  transform: translateX(0);
}
.breadcrumbs{
  display: flex;
  justify-content: start;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.breadcrumbs li{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "Montserrat-SemiBold";
  color: #fff;
  margin-right: 2rem;
}
.breadcrumbs li:last-child{
  color: #707070;
}
.breadcrumbs li:after{
  position: absolute;
  content: '';
  font-family: "Montserrat-SemiBold";
  font-size: 1.125rem;
  color: #fff;
  top: 0;
  right: -1.2rem;
  background-image: url('data:image/svg+xml;utf8,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.7094 7.45441C9.09865 7.84613 9.09659 8.4793 8.70479 8.86861L2.32011 15.2129C1.92831 15.6022 1.29515 15.6003 0.905901 15.2086C0.516652 14.8168 0.518716 14.1837 0.910513 13.7944L6.58579 8.15497L0.947409 2.48071C0.55816 2.08899 0.560225 1.45583 0.952021 1.06651C1.34382 0.677188 1.97698 0.67914 2.36623 1.07087L8.7094 7.45441ZM7.00326 7.15626L8.00326 7.15934L7.99673 9.15933L6.99674 9.15624L7.00326 7.15626Z" fill="white"/></svg>');
  width: 10px;
  height: 100%;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs li:last-child:after{
  display: none;
}
.article{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.article-list{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}
.article-list .article{
  width: 30%;
  /* height: 400px; */
  position: relative;
  margin: 0 auto;
  transition: .5s all;
}
.article-list .large-articles {
  width: 46%;
  margin: 1rem 2%;
}
.article-list-title {
  width: 100%;
  text-align: center;
  font-family: "Agrandir-GrandHeavy";
  text-transform: uppercase;
  margin: 1rem auto 3rem;
}
.article-list .article:hover .more-btn{
  opacity: 1;
  transform: scale(1);
}
.article .date,
.article .article-name {
  display: block;
}
.article .article-description{
  font-family: "Agrandir-GrandHeavy";
  font-size: 2rem;
  margin: 2rem 0 .5rem;
  position: relative;
}
.article .article-description:before{
  content: '';
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  max-width: 12.857rem;
  height: 0.286rem;
  background: linear-gradient(to right, rgba(148, 68, 191, 1), rgba(128, 139, 230, 1), rgba(57, 236, 229, 1));
}
.description-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.article .article-name {
  position: relative;
  font-family: "Montserrat-Bold";
  line-height: 148%;
  display: block;
  letter-spacing: 0.07rem;
  margin: 1rem 0 .5rem;
  font-size: 1.125rem;
  line-height: 1.6rem;
  text-transform: uppercase;
}
.article .description{
  font-family: Montserrat;
  font-size: 1.125rem;
  letter-spacing: 0.08rem;
  line-height: 1.6rem;
  display: block;
  transition: .5s all;
}
.article .more-btn{
  margin-top: 1rem;
  opacity: 0;
  cursor: pointer;
  transform: scale(.7);
  transition: .5s all;
}
.article-category-label {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-family: 'Montserrat-SemiBold';
  padding: .5rem 1rem;
  margin-top: 1rem;
  width: fit-content;
  background-color: var(--btn-background);
  border-radius: 100px;
}
.article-picture-preview {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.pagination-controls-container ul {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.pagination-controls-container ul li {
  margin: 0 .5rem;
}
.pagination-controls-container ul li,
.pagination-controls-container ul li {
  cursor: pointer;
}
.pagination-controls-container ul li.previous {
  margin-right: 1.5rem;
}
.pagination-controls-container ul li.next {
  margin-left: 1.5rem;
}
.pagination-controls-container ul li.previous a svg path,
.pagination-controls-container ul li.next a svg path {
  fill: var(--text-color-white);
  transition: fill .5s;
}
.pagination-controls-container ul li.previous:hover a svg path,
.pagination-controls-container ul li.next:hover a svg path {
  fill: var(--main-color);
}
.pagination-controls-container ul li.selected {
  padding: .3rem;
  border-radius: 10px;
  background-color: var(--main-color);
}
.pagination-controls-container ul li.selected a {
  color: var(--text-color-white);
}
@media (max-width: 767px) {
  .pagination-controls-container ul {
    margin-top: 0;
  }
  .main-page-title-left h2,
  .main-page-title h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .article-list.swiper-list {
    position: absolute;
    width: 100vw;
    left: 0;
  }
  .article-list.swiper-list .swiper {
    width: 95%;
  }
}
@media (max-width: 420px) and (max-height: 740px) {
  .main-page-title-left h2,
  .main-page-title h2 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-top: 1rem;
  }
}

.go-back {
  position: fixed;
  top: 1.5rem;
  right: 15rem;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid var(--text-color-white);
  border-radius: 50%;
  background-color: transparent;
  z-index: 100;
  cursor: pointer;
}
.go-back.show {
  display: flex;
}
.go-back.hide {
  display: none;
}
.go-back:hover {
  background-color: var(--main-color);
}

.go-back img {
  transform: rotate(90deg);
}

.side-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.side-block li {
  display: flex;
  height: 10rem;
  width: 95%;
  max-width: 22rem;
  margin: .5rem 0;
  color: var(--text-color-black);
  border: 1px solid var(--text-color-white);
  border-radius: 20px;
  transition: .5s all;
  cursor: pointer;
}
.side-block li img {
  width: 50%;
}
.side-block li:hover img {
  transform: rotate(225deg);
}
.side-block li:first-child {
  background-color: var(--form-background);
}
.side-block li:nth-child(2) {
  background-color: var(--main-color);
}
.side-block li:nth-child(3) {
  background-color: var(--orange);
}
.side-block li:last-child {
  color: var(--text-color-white);
  background-color: transparent;
}
.side-block li:last-child img {
  filter: invert(1);
}
.side-block li:hover {
  filter: brightness(85%);
}
.side-block .side-title:hover{
  box-shadow: 0px 0px 10px 2px #fff;
}
.side-block .side-title:first-child{
  background: var(--form-background);
  color: var(--main-background);
}
.side-block .side-title:nth-child(2){
  background: var(--main-color);
  color: var(--main-background);
}

.side-block .side-title:nth-child(3){
  background: var(--orange);
  color: var(--main-background);
}
.side-block .side-title:nth-child(4){
  background: transparent;
  color: var(--text-color-white);
  border: 1px solid var(--text-color-white);
}
.side-title-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  padding: 0 1rem;
  font-family: "Gatwick-Bold";
  font-size: 1.2rem;
  border-right: 1px solid var(--text-color-black);
}
.side-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  cursor: pointer;
}
.side-arrow-right{
  width: 1.875rem;
  position: relative;
  transform: rotate(-90deg);
  filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(64%);
  margin-left: 1rem;
  transition: .5s all;
  cursor: pointer;
}
.side-arrow-right:hover{
  width: 2.188rem;
  transform: rotate(-135deg);
}
.side-block .side-title:nth-child(4) .side-arrow-right{
  filter: invert(1%) sepia(1%) saturate(11%) hue-rotate(123deg) brightness(85%) contrast(64%);
}

@media (max-width: 1300px) {
  .main-page-container,
  .content-container,
  .news-career-content-container {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .content-container {
    max-width: 1140px;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 12px;
  }
  .content-container {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .main-page-container {
    width: 90%;
    justify-content: center;
  }
  .content-container {
    max-width: 720px;
  }
  .main-container-pt {
    padding-top: 5rem;
  }
  .go-back {
    top: 1.7rem;
    right: 8rem;
    height: 35px;
    width: 35px;
  }
  .go-back.partner {
    top: 1.3rem;
    right: 7rem;
    height: 30px;
    width: 30px;
  }
  .go-back img {
    width: 90%;
  }
  .news-career-content-container {
    min-height: calc(100vh - 315px);
    padding: 0;
  }
}

@media (max-width: 576px) {
  .content-container {
    max-width: 100%;
  }
  .breadcrumbs {
    margin-left: 1rem;
  }
  .article-list .article {
    height: auto;
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
  .article .date{
    font-size: 0.875rem;
  }
  .article-picture-preview{
    width: 100%;
    height: auto;
  }
  .article-category-label {
    margin-top: 1rem;
    display: block;
  }
  .article .article-name{
    /*margin: 15px 0;*/
    font-size: 1.125rem;
    line-height: 2rem;
    margin-bottom: 10px;
  }
  .article-list .article:hover .article-name{
    transform: translateY(0);
    line-height: 1.9rem;
  }
  .article .more-btn {
    opacity: 1;
    bottom: 10px;
    right: 15px;
  }
}

@media (min-width: 1800px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 2500px) {
  html {
    font-size: 20px;
  }
}

.box {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: green;
  font-weight: 600;
  margin-top: 10rem;
  z-index: 9999999999;
  color: white;
}

.cursor,
.aura {
  position: absolute;
  border-radius: 100%;
  transition: .5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, .2s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 9999;
}

.cursor {
  width: 8px;
  height: 8px;
  background-color: rgba(#fff, .5);
}

.aura {
  width: 46px;
  height: 46px;
  border: 1px solid rgba(#fff, .2);
  background-color: rgba(#fff, .027);
  transform: translate(5px, 5px);
}
