.app {
  background-color: transparent;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--main-background);
  z-index: 100;
}
.loader-container div {
  width: auto;
  height: 10%;
}
