.cookie-bar {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    height: fit-content;
    box-sizing: border-box;
    background-color: transparent;
    z-index: 999;
}
.cookie-bar-content-cover {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    text-align: center;
    padding: 4rem 2rem 2rem;
    background-color: var(--main-color);
    border-radius: 15px;
}
.cookie-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Agrandir-GrandHeavy";
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    height: 40%;
    color: var(--text-color-white);
    z-index: 1;
}
.cookie-title p {
    width: 100%;
    margin: 0;
}
.cookie-title::after {
    content: '';
    position: absolute;
    bottom: -.5rem;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,#9444bf,#808be6,#39ece5);
}
.cookie-title img {
    margin-left: 1rem;
}
.cookie-bar p {
    margin: 0;
}
.cookies-btn-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.cookie-accept,
.cookie-more {
    width: 40%;
    font-family: "Montserrat-SemiBold";
    font-size: 1.3rem;
    padding: 1rem 0;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 100px;
    color: var(--text-color-white);
    transition: background-color .5s;
}
.cookie-accept {
    background-color: var(--accent);
}
.cookie-more {
    background-color: #989C9C;
}
.cookie-accept:hover {
    background-color: #20a59f;
    color: var(--text-color-white);
}
.cookie-more:hover {
    background-color: #868989;
    color: var(--text-color-white);
}
.cookie-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--text-color-white);
    height: 60%;
    padding: 2rem;
    padding-top: 1rem;
}

@media (max-width: 767px) {
    .cookie-bar {
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .cookie-bar-content-cover {
        flex-direction: column;
        text-align: center;
    }
}
